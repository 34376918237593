function Education() {
    return (
        <div>
            <section className="colorlib-education" data-section="education">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Education</span>
                            <h2 className="colorlib-heading animate-box">Education</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                            <div className="fancy-collapse-panel">
                                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingOne">
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Master | Columbia University | Aug 2015 - Dec 2016</a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                            <div className="panel-body">
                                                <p>Mathematics of Finance</p>
                                                <ul>
                                                    <li>Example Coursework: Time-Series Modelling, C++ Programming for Quantitative and Computational Finance, Numerical Methods in Finance, Multi-Asset Portfolio Management, Financial Risk Management</li>
                                                    <li>Course project: <a href="https://risk-mgmt-eevee.herokuapp.com/" target="_blank" rel="noopener noreferrer">Web-based Risk Calculation Engine</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingTwo">
                                            <h4 className="panel-title">
                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Fellowship | The Data Incubator | Jun 2016 - Aug 2016</a>
                                            </h4>
                                        </div>
                                        <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                            <div className="panel-body">
                                                <p>Data Science</p>
                                                <ul>
                                                    <li>Intensive data science bootcamp admitting fewer than 3% of thousands of applicants</li>
                                                    <li>Weekly projects: Identifying popular celebrities using Web scraping and network analysis; analyzing restaurant inspection data by SQL; predicting Yelp score using scikit-learn; Wikipedia linguistic analysis by MapReduce…</li>
                                                    <li><a href="http://nyc-taxi-trips.herokuapp.com/" target="_blank" rel="noopener noreferrer">Capstone project</a>: analyzed NYC taxi and Uber trip data; built a web application to predict cab fare and trip duration given locations</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="headingThree">
                                            <h4 className="panel-title">
                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Bachelor | Wesleyan University | Aug 2011 - May 2015</a>
                                            </h4>
                                        </div>
                                        <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                            <div className="panel-body">
                                                <p>Double Major: Math-Econ; Dance</p>
                                                <ul>
                                                    <li><a href="https://wesscholar.wesleyan.edu/etd_hon_theses/1419/" target="_blank" rel="noopener noreferrer">Honors Thesis </a> in Mathematics-Economics</li>
                                                    <li>Dean's List</li>
                                                    <li>Completed the <a href="https://www.wesleyan.edu/dance/" target="_blank" rel="noopener noreferrer">Dance major</a> after starting to dance the freshman year</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Education;

function Sidebar() {
    return (
        <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
                <div className="author-img" style={{ backgroundImage: 'url(images/about.jpg)' }} />
                <h1 id="colorlib-logo"><a href="index.html">Harry Zhu</a></h1>
                <span className="position">
                    Data Scientist & Engineer<br />
                    Woodworker<br />
                    Dad & Dog Dad
                    </span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
                <div id="navbar" className="collapse">
                    <ul>
                        <li className="active"><a href="#about" data-nav-section="about">About</a></li>
                        <li><a href="#experience" data-nav-section="experience">Experience</a></li>
                        <li><a href="#education" data-nav-section="education">Education</a></li>
                        <li><a href="#skills" data-nav-section="skills">Skills</a></li>
                        <li><a href="#projects" data-nav-section="projects">Projects</a></li>
                    </ul>
                </div>
            </nav>
            <div className="colorlib-footer">
                <ul>
                    <li><a href="https://linkedin.com/in/hengyizhu" target="_blank" rel="noreferrer"><i className="icon-linkedin2" /></a></li>
                    <li><a href="https://github.com/Hengyi-Zhu" target="_blank" rel="noreferrer"><i className="icon-github" /></a></li>
                    <li><a href="https://www.instagram.com/witty.the.golden/" target="_blank" rel="noreferrer"><i className="icon-instagram" /></a></li>
                </ul>

                <p><small>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */} Design inspiration from <a href="https://colorlib.com" target="_blank" rel="noreferrer">Colorlib</a>
                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</small></p>
            </div>
        </aside>
    )
}

export default Sidebar;

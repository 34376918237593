import './App.css';
import Sidebar from './components/sidebar';
import Education from './components/education';
import About from './components/about';
import Experience from './components/experience';
import Skills from './components/skills';
import Projects from './components/projects';


function App() {
  return (
    <div id="colorlib-page">
      <div id="container-wrap">
        <Sidebar/>
        <div id="colorlib-main">
          <About/>
          <Experience/>
          <Education/>
          <Skills/>
          <Projects/>
        </div>
      </div>
    </div>
  );
}

export default App;

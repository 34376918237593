function About() {
    return (
        <div>
            <section className="colorlib-about" data-section="about">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                                <div className="col-md-12">
                                    <div className="about-desc">
                                        <span className="heading-meta">About Me</span>
                                        <h2 className="colorlib-heading">Who Am I?</h2>
                                        <p>Hi. This is Harry Zhu. I am an experienced data scientist who is also passionate about engineering. I currently work at CVS Health as a Senior Manager in Data Science, leading a team to help guide our members to make better health choices. Before that, I was responsible for building and scaling the data science capacity at Domio (a startup in the travel and hospitality space).</p>
                                        <p>I love building things, both digital and physical. I am in the middle of building more furniture for my home. I also built this website to have some fun with front end technologies.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                                    <div className="services color-1">
                                        <span className="icon2"><i className="icon-book" /></span>
                                        <h3>Data Science</h3>
                                    </div>
                                </div>
                                <div className="col-md-3 animate-box" data-animate-effect="fadeInRight">
                                    <div className="services color-2">
                                        <span className="icon2"><i className="icon-code" /></span>
                                        <h3>Engineering</h3>
                                    </div>
                                </div>
                                <div className="col-md-3 animate-box" data-animate-effect="fadeInTop">
                                    <div className="services color-3">
                                        <span className="icon2"><i className="icon-chart-line" /></span>
                                        <h3>Product & Strategy</h3>
                                    </div>
                                </div>
                                <div className="col-md-3 animate-box" data-animate-effect="fadeInBottom">
                                    <div className="services color-4">
                                        <span className="icon2"><i className="icon-map" /></span>
                                        <h3>Leadership</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                                    <div className="hire">
                                        <h2>Please feel free to connect with me!</h2>
                                        <a href="https://linkedin.com/in/hengyizhu" target="_blank" rel="noopener noreferrer" className="btn-hire">Contact me</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About;

function Projects() {
    return (
        <div>
            <section className="colorlib-projects" data-section="projects">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">My projects</span>
                            <h2 className="colorlib-heading animate-box">Past projects (any kind)</h2>
                        </div>
                    </div>
                    {/*<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">*/}
                    {/*    <div className="col-md-12">*/}
                    {/*        <p className="projects-menu"><span><a href="#" className="active">Graphic Design</a></span> <span><a href="#">Web Design</a></span> <span><a href="#">Software</a></span> <span><a href="#">Apps</a></span></p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
                            <div className="project" style={{ backgroundImage: 'url(images/shoe_rack.jpeg)' }}>
                                <div className="desc">
                                    <div className="con">
                                        <h3><a target="_blank" rel="noopener noreferrer" href="images/shoe_rack.jpeg">Shoe Rack</a></h3>
                                        <span>Woodworking</span>
                                        <br></br>
                                        <li>Made with hard maple & walnut</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
                            <div className="project" style={{ backgroundImage: 'url(images/dining_table.jpeg)' }}>
                                <div className="desc">
                                    <div className="con">
                                        <h3><a target="_blank" rel="noopener noreferrer" href="images/dining_table.jpeg">Dining Table</a></h3>
                                        <span>Woodworking</span>
                                        <br></br>
                                        <li>Made with walnut</li>
                                        <li>Base from <a target="_blank" rel="noopener noreferrer" href="https://flowyline.com/">FLOWYLINE</a></li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInTop">
                            <div className="project" style={{ backgroundImage: 'url(images/bowls.jpeg)' }}>
                                <div className="desc">
                                    <div className="con">
                                        <h3><a target="_blank" rel="noopener noreferrer" href="images/bowls.jpeg">Small bowls</a></h3>
                                        <span>Pottery</span>
                                        <br></br>
                                        <li>Made in <a target="_blank" rel="noopener noreferrer" href="https://www.fireborn.com/">Fireborn Studios</a> with the help from Dan & Donna</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-md-6 animate-box" data-animate-effect="fadeInBottom">*/}
                        {/*    <div className="project" style={{ backgroundImage: 'url(images/img-4.jpg)' }}>*/}
                        {/*        <div className="desc">*/}
                        {/*            <div className="con">*/}
                        {/*                <h3><a target="_blank" rel="noopener noreferrer" href="projects.html">projects 04</a></h3>*/}
                        {/*                <span>Pottery</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">*/}
                        {/*    <div className="project" style={{ backgroundImage: 'url(images/img-5.jpg)' }}>*/}
                        {/*        <div className="desc">*/}
                        {/*            <div className="con">*/}
                        {/*                <h3><a href="projects.html">projects 05</a></h3>*/}
                        {/*                <span>Graphic, Logo</span>*/}
                        {/*                <p className="icon">*/}
                        {/*                    <span><a href="#"><i className="icon-share3" /></a></span>*/}
                        {/*                    <span><a href="#"><i className="icon-eye" /> 100</a></span>*/}
                        {/*                    <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6 animate-box" data-animate-effect="fadeInRight">*/}
                        {/*    <div className="project" style={{ backgroundImage: 'url(images/img-6.jpg)' }}>*/}
                        {/*        <div className="desc">*/}
                        {/*            <div className="con">*/}
                        {/*                <h3><a href="projects.html">projects 06</a></h3>*/}
                        {/*                <span>Web Design</span>*/}
                        {/*                <p className="icon">*/}
                        {/*                    <span><a href="#"><i className="icon-share3" /></a></span>*/}
                        {/*                    <span><a href="#"><i className="icon-eye" /> 100</a></span>*/}
                        {/*                    <span><a href="#"><i className="icon-heart" /> 49</a></span>*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12 animate-box">
                            <p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>
                        </div>
                    </div> */}
                </div>
            </section>
        </div>
    )
}

export default Projects;
function Experience() {
    return (
        <div>
            <section className="colorlib-experience" data-section="experience">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">Experience</span>
                            <h2 className="colorlib-heading animate-box">Work Experience</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="timeline-centered">
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-6">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Senior Manager, Data Science<br /><span><a href="https://jobs.cvshealth.com/" target="_blank" rel="noopener noreferrer">CVS Health</a> | Sep 2022 - Present</span></h2>
                                            <p>Medicaid Consumer Analytics & Behavior Change Team</p>
                                            <ul>
                                                <li>Lead Medicaid team internal data science optimization projects, including GCP migrations, designing and coding campaign evaluation frameworks, and automation</li>
                                                <li>Lead a team of data scientists and collaborate across organizations to expand the chronic conditions care portfolio (respiratory conditions, diabetes, heart health, Rx adherence) to achieve $8M annual medical cost savings</li>
                                                <li>Establish team norms on coding best practices (code review, environment management) and grow the team's technical capabilities</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-5">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Senior Data Scientist<br /><span><a href="https://jobs.cvshealth.com/" target="_blank" rel="noopener noreferrer">CVS Health</a> | Jun 2020 - Aug 2022</span></h2>
                                            <p>Medicaid Consumer Analytics & Behavior Change Team</p>
                                            <ul>
                                                <li>Implemented Rx adherence campaign that outreaches daily to thousands of members targeted by an XGBoost model, improving medication adherence rate by 2%, resulting in $2M+ annual medical cost savings</li>
                                                <li>Designed and implemented a portfolio of chronic conditions-related campaigns through digital channels and reduced adverse events that happened to our member</li>
                                                <li>Mentored team members and promoted data science & engineering best practices</li>
                                                <li>Built knowledge sharing site through GitHub Pages, created a team python library to optimize data science tasks, and grew users throughout the organization</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-4">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Data Scientist<br /><span><a href="https://www.staydomio.com/" target="_blank" rel="noopener noreferrer">Domio</a> | Aug 2017 - Mar 2020</span></h2>
                                            <p>Responsible for Domio's entire data science and data engineering system (and first-ever data hire)</p>
                                            <ul>
                                                <li>Designed and built 20+ ETL pipelines into BigQuery ingesting TBs of data from various types of sources using Airflow, PubSub, Python, celery, rabbitMQ on GCP</li>
                                                <li>Created views and managed BI system serving the whole organization of 100+ people</li>
                                                <li>Built and iterated on NLP models to improve guest experience contributing to an industry-leading NPS level of 80+</li>
                                                <li>Designed and implemented recursive algorithms to discover 1M+ listings from various websites covering the entire U.S. to provide a complete view into the market</li>
                                                <li>Hosted JupyterHub for frictionless knowledge sharing and notebook-based runbooks</li>
                                                <li>Coached 30+ non-technical colleagues to use data effectively and to be data-driven</li>
                                                <li>Created data tools to speed up market discovery and competitive landscaping by 12x</li>
                                                <li>Backend software engineering and infrastructure development such as building and integrating APIs, setting up database migrations, and improving deployment scripts</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-3">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Associate<br /><span><a href="https://www.argusinformation.com/" target="_blank" rel="noopener noreferrer">Verisk Financial - Argus</a> | Mar 2017 - Aug 2017</span></h2>
                                            <p>Integrated Consumer Banking Solutions Team</p>
                                            <ul>
                                                <li>Provided commercial bank clients with data-driven benchmarking services on deposit portfolios that identify top opportunities to increase portfolio value by millions</li>
                                                <li>Prepared management presentations and advised clients on potentials to increase retention by 18% through the adoption of P2P payments capacity</li>
                                                <li>Innovated within team by improving batch processes and introducing 5+ colleagues to data science techniques and solutions</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-2">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Fall Intern<br /><span><a href="https://www.jpmorganchase.com/" target="_blank" rel="noopener noreferrer">JPMorgan Chase & Co.</a> | Oct 2016 - Dec 2016</span></h2>
                                            <p>Wholesale Credit Analytics and Solutions (WCAS)</p>
                                            <ul>
                                                <li>Coded Bash scripts to automate report-uploading process in a remote system</li>
                                                <li>Wrote SQL queries to aggregate information from TBs of data for risk analysis</li>
                                                <li>Collaborated with both tech and quant research groups on documentations and results reconciliations</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-1">
                                            <i className="icon-pen2" />
                                        </div>
                                        <div className="timeline-label">
                                            <h2>Various Campus Jobs<br /><span><a href="https://www.wesleyan.edu/" target="_blank" rel="noopener noreferrer">Wesleyan University</a> | Sep 2011 - Dec 2014</span></h2>
                                            <p>Teaching Apprentice</p>
                                            <ul>
                                                <li>Applied Data Analysis</li>
                                                <ul>
                                                    <li>Led weekly peer mentoring sessions</li>
                                                    <li>Assisted students in conducting research projects using Stata</li>
                                                    <li>Evaluated possible data to incorporate for future classes</li>
                                                </ul>
                                                <li>Digging the Digital Era: A Data Science Primer</li>
                                                <ul>
                                                    <li>Provided in-class support in R for students conducting big data analysis</li>
                                                    <li>Assisted professor in responding to students’ questions</li>
                                                </ul>
                                            </ul>
                                            <p>Peer Tutor</p>
                                            <ul>
                                                <li>Provide one-on-one tutoring support for students taking Microeconomics Analysis</li>
                                            </ul>
                                            <p>Research Fellow</p>
                                            <ul>
                                                <li>Selected by Quantitative Analysis Center advisory board as a QAC summer apprentice</li>
                                                <li>Took intensive classes in data analysis using SPSS, SAS, Stata and R</li>
                                                <li>Conducted research on the long tail in online news, that whether small news sites are gaining more exposure through online news aggregators</li>
                                            </ul>
                                            <p>Research Assistant</p>
                                            <ul>
                                                <li>Faculty-student Research Fellowship with Professor <a href="http://chogendorn.web.wesleyan.edu/" target="_blank" rel="noopener noreferrer">Christiaan Hogendorn</a></li>
                                                <li>Employed Python to extract HTML from every Google News page archived on Archive.org and created a data set containing detailed information regarding more than a million news entries</li>
                                            </ul>
                                            <p>Course Assistant</p>
                                            <ul>
                                                <li>Instructed students on Chinese music instruments as part of a 30 person ensemble</li>
                                                <li>Performed Pipa on various occasions, solo at workshops and as section leader for concerts with more than 100 attendees</li>
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                                <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                                    <div className="timeline-entry-inner">
                                        <div className="timeline-icon color-none">
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Experience;

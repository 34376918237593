function Skills() {
    return (
        <div>
            <section className="colorlib-skills" data-section="skills">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                            <span className="heading-meta">My Specialty</span>
                            <h2 className="colorlib-heading animate-box">My Skills</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                            <div className="progress-wrap">
                                <h3>Proficient with production experience</h3>
                                <div className="progress">
                                    <div className="progress-bar color-1" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{ width: '90%' }}>
                                    </div>
                                </div>
                                <ul>
                                    <li>Python (Flask, SQLAlchemy, Celery, Selenium, Dask)</li>
                                    <li>SQL (Postgres, BigQuery, MS SQL Server)</li>
                                    <li>Experiment design and AB testing</li>
                                    <li>Data Science (Jupyter, Scikit-learn, Pandas, NLP, R)</li>
                                    <li>GCP (K8s, PubSub, Cloud Functions, Cloud Storage, Cloud Composer)</li>
                                    <li>Airflow</li>
                                    <li>Infra & Dev (Bash, Git, Docker, Helm)</li>
                                    <li>Business Intelligence (Chartio, Looker, Tableau, Plotly, Bokeh)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInRight">
                            <div className="progress-wrap">
                                <h3>Played around with</h3>
                                <div className="progress">
                                    <div className="progress-bar color-2" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: '70%' }}>
                                    </div>
                                </div>
                                <ul>
                                    <li>React & Javascript</li>
                                    <li>AWS</li>
                                    <li>Snowflake</li>
                                    <li>Apache Beam & Google Cloud Dataflow</li>
                                    <li>Spark</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Skills;